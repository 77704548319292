import { HStack, Text } from "@chakra-ui/layout";
import { Checkbox } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { EnabledMeetingTypes, MeetingTypeEnum, MeetingTypeForCustomer } from "../../../api/client";
import { useGetBankOptions } from "../../../api/useBankOptionsApi";
import { useUpdateEnabledMeetingTypes } from "../../../api/useMeetingConfigurationApi";
import { ConfigurationOptionItem } from "../../ConfigurationOptionItem";
import { useMeetingConfigurationContext } from "../MeetingConfigurationContext";

export const EnabledMeetingTypesInput = () => {
    const { customerCategories, actualMeetingConfiguration, themeSpecificMeetingConfiguration } = useMeetingConfigurationContext();
    const updateMeetingTypesMutation = useUpdateEnabledMeetingTypes();
    const { data: bankOptions } = useGetBankOptions();
    const { t } = useTranslation();
    const [enabledMeetingTypesState, setEnabledMeetingTypesState] = useState<EnabledMeetingTypes[]>(actualMeetingConfiguration?.enabledMeetingTypes || []);

    function handleEnabledMeetingTypesChanged(type: MeetingTypeEnum, indeterminate: boolean, checked: boolean, customerCategoryId?: string) {
        const updatedMeetingTypes = getUpdatedMeetingTypes(type, indeterminate, checked, customerCategoryId);

        // manually update state to avoid waiting for the mutation to complete
        updateState(customerCategoryId, type, checked, !updatedMeetingTypes.some(x => x.type === type));

        updateMeetingTypesMutation.mutate({ id: actualMeetingConfiguration!.id || undefined, enabledMeetingTypes: { types: updatedMeetingTypes, customerCategoryId } });
    }

    function updateState(customerCategoryId: string | undefined, type: MeetingTypeEnum, checked: boolean, removed: boolean) {
        const actualMeetingTypes = actualMeetingConfiguration?.enabledMeetingTypes!.find(x => x.customerCategoryId === customerCategoryId)?.types;
        let mergedTypes = [...actualMeetingTypes ?? []];
       
        if (removed) {
            mergedTypes = mergedTypes.filter(x => x.type !== type);
        }
        else {
            // find the index of the type that was changed
            const index = mergedTypes.findIndex(x => x.type === type);
            // if the type was found, update the enabled value
            if (index !== -1) {
                mergedTypes[index].enabled = checked;
            }
            else {
                // if the type was not found, add the type with the enabled value
                mergedTypes.push({ type, enabled: checked });
            }
        }
        // take the actual meeting types and update the meeting type that was changed
        let cat = enabledMeetingTypesState.find(x => x.customerCategoryId === customerCategoryId) ?? { customerCategoryId, types: [] };

        // add category to state
        if (!enabledMeetingTypesState.some(x => x.customerCategoryId === customerCategoryId)) {
            if (cat.types.length === 0 || !cat.types.some(x => x.type === type)) {
                cat.types = [
                    ...cat.types,
                    { type, enabled: checked }
                ];
            }
            setEnabledMeetingTypesState([...enabledMeetingTypesState, cat]);
        } else {
            setEnabledMeetingTypesState(enabledMeetingTypesState.map(x => x.customerCategoryId === customerCategoryId ? { customerCategoryId, types: mergedTypes } : x));
        }

        
    }

    function getUpdatedMeetingTypes(type: MeetingTypeEnum, indeterminate: boolean, checked: boolean, customerCategoryId?: string): MeetingTypeForCustomer[] {
        // The currently enabled meeting types for the aggregated configuration for the customer category and theme
        let currentMeetingTypesForTheme = themeSpecificMeetingConfiguration?.enabledMeetingTypes?.find(x => x.customerCategoryId === customerCategoryId)?.types;
        if (currentMeetingTypesForTheme === undefined) {
            // If the theme does not have any enabled meeting types for the customer category, use the actual meeting types
            currentMeetingTypesForTheme = actualMeetingConfiguration?.enabledMeetingTypes?.find(x => x.customerCategoryId === customerCategoryId)?.types;
        }

        let updatedMeetingTypes: MeetingTypeForCustomer[] = [];
        // If the currentMeetingTypes is undefined, create a new array with the selected type
        if (currentMeetingTypesForTheme === undefined) {
            return [{ enabled: checked, type }];
        }

        const typeIndex = currentMeetingTypesForTheme?.findIndex(x => x.type === type);
        if (typeIndex !== -1) {
            // If the checkbox is not indeterminate and is checked, The type is removed from the array of types
            // This sets the value of the checkbox to indeterminate, which allows the user to cycle between checked, unchecked and indeterminate
            if (!indeterminate && checked && customerCategoryId !== undefined) {
                updatedMeetingTypes = [...currentMeetingTypesForTheme.slice(0, typeIndex), ...currentMeetingTypesForTheme.slice(typeIndex + 1)]
            }

            // If the checkbox is not indeterminate and is unchecked, The type is added to the array of types
            else {
                updatedMeetingTypes = [...currentMeetingTypesForTheme.slice(0, typeIndex), { enabled: checked, type }, ...currentMeetingTypesForTheme.slice(typeIndex + 1)]
            }
            return updatedMeetingTypes;
        }

        // If the type is not in the array of types, add it to the array
        return [...currentMeetingTypesForTheme, { enabled: checked, type }];
    }

    return (
        <ConfigurationOptionItem title={t('meetingconfigurationpage.input.enabled-meetingtypes')} customerCategories={customerCategories}>
            {customerCategoryId => {
                const actualMeetingTypes = actualMeetingConfiguration?.enabledMeetingTypes!;
                const actualCustomerCategoryMeetingTypes = enabledMeetingTypesState.find(x => x.customerCategoryId === customerCategoryId);

                const themeMeetingTypes = themeSpecificMeetingConfiguration?.enabledMeetingTypes;
                const themeCustomerCategoryMeetingTypes = themeMeetingTypes?.find(x => x.customerCategoryId === customerCategoryId);
                return (
                    <HStack spacing={10} direction="row" alignItems="start">
                        {Object.values(MeetingTypeEnum).map(meetingType => {
                            const isIndeterminate: boolean = !actualCustomerCategoryMeetingTypes?.types.find(x => x.type === meetingType);
                            const isChecked: boolean = !!actualCustomerCategoryMeetingTypes?.types.find(x => x.type === meetingType)?.enabled;
                            const isConfiguredOnTheme = themeCustomerCategoryMeetingTypes?.types.find(x => x.type === meetingType) != null;

                            return (
                                <Checkbox key={meetingType} size="lg" isIndeterminate={isIndeterminate} isChecked={isChecked} 
                                   borderBottomWidth={isConfiguredOnTheme ? 2 : "0px"} borderBottomStyle="dashed" marginBottom={isConfiguredOnTheme ? -2 : "0px"}
                                    onChange={(e) => handleEnabledMeetingTypesChanged(meetingType, e.target.indeterminate, e.target.checked, customerCategoryId)}>
                                    <Text>{bankOptions?.meetingTypeLabels.find(x => x.name === meetingType)?.label}</Text>
                                </Checkbox>
                            );
                        })}
                    </HStack>
                );
            }}
        </ConfigurationOptionItem>
    );
};


