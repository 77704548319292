import { useEffect } from "react";
import { useGetEmbedUrl } from "../api/useEmbedApi";

export const PortalsPage = () => {
  const { data: embedUrl } = useGetEmbedUrl();

  useEffect(() => {
    if (embedUrl) {
      window.location.href = `${embedUrl}/bookme/portals/portals`;
    }
  }, [embedUrl]);

  return null;
};
